// Import dependencies
import axios from 'axios'
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setup } from 'axios-cache-adapter'

const imageServerUrl = process.env.VUE_APP_IMAGE_SERVER_URL
const fileServerUrl = process.env.VUE_APP_FILE_SERVER_URL

const baseURL = process.env.VUE_APP_SIA_API_V2_URL
const token = () => localStorage.getItem('accessToken')

const getCacheableApi = async () => {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver)

  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      /* eslint no-underscore-dangle: 0 */
      memoryDriver._driver,
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'sia-cache',
  })

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    baseURL,
    headers: {
      Authorization: (token()) ? `bearer ${token()}` : '',
    },
    // `axios-cache-adapter` options
    cache: {
      maxAge: 60 * 60 * 1000,
      store: forageStore, // Pass `localforage` store to `axios-cache-adapter`
    },
  })
}

// const api__ = axios.create({
//   baseURL,
//   headers: {
//     Authorization: (token()) ? `bearer ${token()}` : '',
//   },
// })

const api = () => axios.create({
  baseURL,
  headers: {
    Authorization: (token()) ? `bearer ${token()}` : '',
  },
})

const upload = () => axios.create({
  baseURL,
  headers: {
    Authorization: (token()) ? `bearer ${token()}` : '',
    'Content-Type': 'multipart/form-data',
  },
})

const download = () => axios.create({
  baseURL,
  headers: {
    Authorization: (token()) ? `bearer ${token()}` : '',
  },
  responseType: 'blob',
})

const setToken = value => {
  localStorage.setItem('accessToken', value)
}

// export function test() {
//   console.log('TEST')
// }

export {
  getCacheableApi,
  api,
  upload,
  download,
  setToken,
  imageServerUrl,
  fileServerUrl,
}
