<template>
  <div>
    <div @click="tambah">
      <fab
        :z-index="index"
        :position="position"
        :bg-color="bgColor"
        :main-icon="mainIcon"
      />
    </div>
    <b-modal id="modal-1" title="Bantuan" size="lg">
      <b-form-group
        label="Topik"
        label-for="name-input"
        invalid-feedback="Name is required"
      >
        <b-form-select v-model="selected" :options="options" />
      </b-form-group>
      <b-form-group
        label="Konten"
        label-for="name-input"
        invalid-feedback="Name is required"
      >
        <div id="quill-toolbar-vue">
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" value="" />
          <button class="ql-align" value="center" />
          <button class="ql-align" value="right" />
          <button class="ql-align" value="justify" />
          <select class="ql-font">
            <option selected />
            <option value="serif" />
            <option value="monospace" />
          </select>
        </div>
        <quill-editor
          v-model="konten"
          :options="editorOptionVue"
          style="min-height:300px;"
        />
      </b-form-group>
      <b-row v-for="(x, y) in lampiran" :key="y" class="pb-2">
        <b-col sm="10">
          <b-form-file
            v-if="x.value"
            v-model="x.lampiran"
            :placeholder="x.value"
            drop-placeholder="Drop file here..."
          />
          <b-form-file
            v-else
            v-model="x.lampiran"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
        <b-col sm="1">
          <b-button variant="danger" @click="hapusLampiran(x.value, y)">
            X
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-button variant="warning" @click="tambahLampiran">
            Tambah Lampiran
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            v-if="isForm === 'tambah'"
            variant="primary"
            class="float-right"
            @click="kirim"
          >
            Kirim
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="float-right"
            @click="simpan"
          >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import fab from 'vue-fab'
import {
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { api, upload } from '@/hap-sia/setup'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BButton,
    fab,
    quillEditor,
  },
  props: ['paramUbah'],
  data() {
    return {
      bgColor: '#2980b9',
      mainIcon: 'contact_support',
      options: [{ value: null, text: '--pilih topik--' }],
      selected: null,
      user_id: null,
      biodata_id: null,
      topik_id: null,
      konten: null,
      pesan_id: null,
      isForm: null,
      lampiran: [{ value: '' }],
      position: 'bottom-left',
      index: '999999',
    }
  },
  watch: {
    paramUbah: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val.lampiran.length > 0) {
            this.lampiran = []
            val.lampiran.forEach(element => {
              this.lampiran.push({
                value: element.nama_file,
              })
            })
          }
          this.isForm = 'ubah'
          this.pesan_id = val.id
          this.konten = val.konten
          this.selected = val.topik_id
          this.$bvModal.show('modal-1')
        }
      },
    },
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      this.user_id = userData.id
      this.biodata_id = userData.biodata_id

      const result = await api().get('inbox/topik')
      result.data.forEach(element => {
        this.options.push({ value: element.id, text: element.nama })
      })
    }
  },
  methods: {
    hapusLampiran(lampiran, index) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Delete',
          okVariant: 'danger',
        })
        .then(async value => {
          if (value) {
            const tmpData = {
              id: this.pesan_id,
              namaFile: lampiran,
              deletedLampiran: true,
            }
            await api().patch('inbox', tmpData)
            this.makeToast('success', 'Sukses', 'Data berhasil dihapus')
            this.lampiran.splice(index, 1)
          }
        })
    },
    tambahLampiran() {
      this.lampiran.push({
        value: '',
      })
    },
    tambah() {
      this.isForm = 'tambah'
      this.$bvModal.show('modal-1')
    },
    async simpan() {
      // console.log(this.lampiran)
      let isIssue = false
      const tmpData = {
        id: this.pesan_id,
        topik_id: this.selected,
        konten: this.konten,
      }
      const tempFiles = []
      if (this.lampiran.length > 0) {
        this.lampiran.forEach((element, y) => {
          // validasi lampiran kosong
          if (!element.lampiran && element.value === '' && y !== 0) {
            this.makeToast(
              'danger',
              'Error',
              'Lampiran Tidak Boleh Kosong!',
              true,
            )
            isIssue = true
            return
          }

          if (element.lampiran) {
            const formData = new FormData()
            formData.append('file', element.lampiran)
            tempFiles.push(
              upload().post(`inbox/${this.$route.params.id}/upload`, formData),
            )
            // console.log(tempFiles)
          }
        })
      }
      if (tempFiles) {
        let promises
        try {
          promises = await Promise.all(tempFiles)
        } catch (error) {
          this.makeToast('danger', 'Error', error, true)
        }
        const tempArray = []
        promises.forEach(element => {
          tempArray.push(element.data.filename)
        })
        tmpData.lampiran = tempArray
      }
      if (!isIssue) {
        try {
          await api().patch('inbox', tmpData)
          this.reset()
          this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
          this.$bvModal.hide('modal-1')
          this.$root.$emit('reloadListener', true)
        } catch (error) {
          this.makeToast('danger', 'Error', error.response, true)
        }
      }
    },
    async kirim() {
      if (this.selected === null || this.konten === null) {
        this.makeToast('danger', 'Error', 'Form Cannot Empty!', true)
        return
      }
      const tmpData = {
        topik_id: this.selected,
        user_id: this.user_id,
        konten: this.konten,
      }

      try {
        const result = await api().post('inbox', tmpData)
        this.pesan_id = result.data.id
        const tmpDataLampiran = {}
        // LAMPIRAN
        const tempFiles = []
        if (this.lampiran) {
          this.lampiran.forEach(element => {
            const formData = new FormData()
            if (element.lampiran) {
              formData.append('file', element.lampiran)
              tempFiles.push(
                upload().post(`inbox/${this.pesan_id}/upload`, formData),
              )
            }
          })
        }
        if (tempFiles) {
          let promises
          try {
            promises = await Promise.all(tempFiles)
          } catch (error) {
            this.makeToast('danger', 'Error', error, true)
          }
          const tempArray = []
          promises.forEach(element => {
            tempArray.push(element.data.filename)
          })
          tmpDataLampiran.lampiran = tempArray
        }
        tmpDataLampiran.pesan_id = this.pesan_id
        await api().post('inbox/lampiran', tmpDataLampiran)

        this.reset()
        this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
        this.$bvModal.hide('modal-1')
        this.$router.push({
          name: 'support-detil',
          params: { id: result.data.id },
        })
      } catch (error) {
        this.makeToast('danger', 'Error', error.response, true)
      }
    },
    reset() {
      this.konten = null
      this.lampiran = [{ value: '' }]
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
  },
  setup() {
    const editorOptionVue = {
      modules: {
        toolbar: '#quill-toolbar-vue',
      },
      theme: 'snow',
      placeholder: 'Ketik disini',
    }
    return {
      editorOptionVue,
    }
  },
}
</script>
<style>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
</style>
