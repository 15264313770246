import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import mitt from 'mitt'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// ACL
import '@/libs/acl'

// VUE ANALYTICS
import VueAnalytics from 'vue-analytics';

// const emitter = mitt()

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// SETUP VUEANALYTICS
Vue.use(VueAnalytics, {
  // id: 'G-MXFLTN3FNX',
  id: process.env.VUE_APP_SIA_RECAPTCHA_SITEKEY,
  router
});

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data: {
    fab_status: null,
    param_ubah_inbox_pesan: null,
  },
  methods: {
    fabStatus(param) {
      this.fab_status = param
    },
    ubahInboxPesan(paramUbah) {
      this.param_ubah_inbox_pesan = paramUbah
    },
  },
  // provide: {
  //   emitter,
  // },
  render(h) {
    return h(App, {
      props: {
        showFab: this.fab_status,
        paramUbahInboxPesan: this.param_ubah_inbox_pesan,
      },
    })
  },
}).$mount('#app')
